.leave-table-container {
  width: 100%;
  overflow-x: auto;
}

.table-filters {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 20px;
}

.table-filters input,
.table-filters select {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  flex: 1;
}

.leave-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.leave-table th,
.leave-table td {
  padding: 10px;
  border: 1px solid #ddd;
  text-align: left;
}

.leave-table th {
  background-color: #f0f0f0;
  font-weight: bold;
}

.leave-table .hide-on-small {
  display: none;
}

.toggle-view-button {
  padding: 10px 20px;
  background-color: #007bff;
  width: 100%;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.toggle-view-button:hover {
  background-color: #0056b3;
}

.pagination {
  display: flex;
  justify-content: center;
  gap: 5px;
  margin-top: 20px;
}

.page-button {
  padding: 5px 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  cursor: pointer;
}

.page-button.active {
  background-color: #007bff;
  color: white;
  border: none;
}

@media screen and (min-width: 1200px) {
  .leave-table .hide-on-small {
    display: table-cell;
  }
}

@media screen and (max-width: 1199px) {
  .table-filters {
    flex-direction: column;
  }
}
