.manhour-statistics-page {
  padding: 20px;
  background-color: #f4f5f7;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.top-section {
  background-color: white;
  padding: 5px 15px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.cards-section {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  flex-wrap: wrap;
}

.statistics-card {
  background-color: white;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 32%;
  min-height: 120px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.statistics-card .ant-list-item {
  padding: 5px 0;
  border-bottom: none;
}

.custom-tabs {
  margin-bottom: 10px;
}

.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab {
  background: #fff;
  border-radius: 8px;
  margin-right: 8px;
  padding: 6px 14px;
}

.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-active {
  background: #40a9ff;
  color: white;
  font-weight: bold;
}

.bottom-section {
  padding: 20px;
  background-color: #fafafa;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.filter-bar {
  display: flex;
  flex-wrap: wrap; /* Allows the elements to wrap on smaller screens */
  gap: 15px; /* Creates space between filter elements */
  margin-bottom: 20px;
}

.search-input {
  width: 200px;
  flex: 1; /* Flex allows it to adapt to available space */
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.select-input {
  width: 200px; /* Set consistent width for select inputs */
  flex: 1; /* Allow inputs to adjust to available space */
  position: relative;
}

.ant-select-selector {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
}

.ant-select-arrow {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.ant-select-selection-item {
  flex-grow: 1;
}

.statistics-table {
  background-color: white;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  overflow-x: auto;
  overflow-y: auto;
  max-height: 400px;
  white-space: nowrap;
}

.chart-container {
  width: 120px;
  height: 50px;
}

@media screen and (min-width: 1200px) {
  .statistics-card {
    max-width: 32%;
  }
}

@media screen and (max-width: 1200px) {
  .cards-section {
    flex-direction: column;
  }

  .statistics-card {
    max-width: 100%;
  }

  .statistics-table {
    overflow-x: auto;
  }
}

@media screen and (max-width: 768px) {
  .statistics-card {
    padding: 8px;
  }

  .cards-section {
    gap: 10px;
  }

  .filter-bar {
    flex-direction: column; /* Stack the filters vertically on small screens */
    gap: 15px;
  }

  .search-input, .select-input {
    width: 100%; /* Full width for better layout on smaller screens */
  }

  .bottom-section {
    overflow: visible;
  }
}

@media screen and (max-width: 450px) {
  .statistics-card {
    padding: 6px;
  }

  .ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab {
    padding: 6px 10px;
    font-size: 14px;
  }
}
