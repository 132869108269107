.admin-manager {
    background-color: var(--color-background);
    padding: var(--card-padding);
    border-radius: var(--card-border-radius);
    box-shadow: var(--box-shadow);
    max-width: 600px;
    
    margin: auto;
    overflow: hidden; /* Prevents any child elements from overflowing */
  }
  
  .admin-manager h2 {
    color: var(--color-dark);
    margin-bottom: var(--padding-1);
    text-align: center; /* Centers the heading */
  }
  
  .admin-manager-form .form-group {
    margin-bottom: var(--padding-1);
    display: flex;
    flex-direction: column;
  }
  
  .admin-manager-form label {
    display: block;
    color: var(--color-info-dark);
    margin-bottom: 0.5rem;
  }
  
  .admin-manager-form input[type="email"],
  .admin-manager-form input[type="password"],
  .admin-manager-form input[type="text"],
  .admin-manager-form select,
  .admin-manager-form input[type="file"] {
    width: 100%;
    padding: 0.5rem;
    border-radius: var(--border-radius-1);
    border: 1px solid var(--color-info-light);
    background-color: var(--color-white); /* Ensures input background is white */
    margin-bottom: 0.5rem; /* Adds space between form fields */
  }
  
  .roles-group {
    display: flex;
    flex-direction: column;
  }
  
  .role-checkbox {
    display: flex;
    align-items: center;
    margin-bottom: 10px; /* Adds some space between checkboxes */
  }
  
  .role-checkbox {
    display: flex;
    align-items: center;
  }
  
  .role-checkbox input[type="checkbox"] {
    background-color: red;
    /* Resetting any potential styling that might hide the checkbox */
    width: auto;
    display: inline-block; /* Ensures checkbox is visible */
    margin-right: 10px;
    height: auto;
    margin: 0;
    margin-right: 10px; /* Space between checkbox and label */
    cursor: pointer;
    /* Applying primary color accent to the checkbox */
    accent-color: var(--color-primary);
  }
  
  .role-checkbox label {
    cursor: pointer;
    user-select: none; /* Prevents text selection */
    /* Using the dark variant color for label text for better readability */
    color: var(--color-dark-variant);
  }
  
  .submit-btn {
    background-color: var(--color-primary);
    color: var(--color-white);
    padding: 0.5rem 1rem;
    border-radius: var(--border-radius-2);
    cursor: pointer;
    border: none;
    transition: background-color 0.3s;
    width: 100%; /* Full width button */
    margin-top: 1rem; /* Adds space above the button */
  }
  
  .submit-btn:hover {
    background-color: var(--color-primary-variant);
  }
  