/* client/src/styles/Dashboard.css */
.dark-theme {
    --color-background: #181a1e;
    --color-white: #202528;
    --color-dark: #edeffd;
    --color-dark-variant: #a3bdcc;
    --color-light: rgba(0, 0, 0, 0.4);
    --box-shadow: 0 2rem 3rem var(--color-light);
}

.text-muted {
    color: var(--color-info-dark);
}

.recent-updates h1,
.sales-analytics h1 {
    font-family: poppins, sans-serif;
    font-weight: 800;
    font-size: 1.8rem;
}

.recent-updates h2,
.sales-analytics h2 {
    font-family: poppins, sans-serif;
    font-size: 24px;
    font-weight: bold;
    color: var(--color-dark-variant);
}

.recent-updates h3,
.sales-analytics h3,
.right h3 {
    font-family: poppins, sans-serif;
    font-size: 0.87rem;
    font-weight: bold;
    color: var(--color-dark-variant);
}

.recent-updates h4 {
    font-family: poppins, sans-serif;
    font-size: 0.8rem;
}

.recent-updates h5,
.sales-analytics h5 {
    font-family: poppins, sans-serif;
    font-weight: bold;
    font-size: 0.77rem;
}

.recent-updates small {
    font-family: poppins, sans-serif;
    font-size: 0.75rem;
}

.sales-analytics h4 {
    font-family: poppins, sans-serif;
    font-size: 0.8rem;
}

.sales-analytics small {
    font-family: poppins, sans-serif;
    font-size: 0.75rem;
}

main h1 {
    font-family: poppins, sans-serif;
    font-size: 20px;
    font-weight: bold;
    color: var(--color-dark-variant);
}

main h2 {
    font-family: poppins, sans-serif;
    font-size: 24px;
    font-weight: bold;
    color: var(--color-dark-variant);
}

main h3 {
    font-family: poppins, sans-serif;
    font-size: 0.87rem;
    color: var(--color-dark-variant);
}

main h4 {
    font-family: poppins, sans-serif;
    font-size: 0.8rem;
    color: var(--color-dark-variant);
}

main h4 {
    font-family: poppins, sans-serif;
    font-size: 0.77rem;
    color: var(--color-dark-variant);
}

main small {
    font-family: poppins, sans-serif;
    font-size: 0.75rem;
    color: var(--color-dark-variant);
}

main {
    margin-top: 1.4rem;
    width: 100%;
}

main .date {
    display: inline-block;
    background: var(--color-light);
    border-radius: var(--border-radius-1);
    margin-top: 1rem;
    padding: 0.5rem 1.6rem;
}

main .date input[type='date'] {
    background: transparent;
    color: var(--color-dark);
}

main .insights {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1.6rem;
    width: 100%;
}
main .insights .middle {
    gap: 10%;
}
main .insights .top-up-coming{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
main .insights .top-up-coming span{
    margin-right: auto;
}
main .insights .left {
    position: relative;
    max-width: 190px;
}

main .insights > div {
    background: var(--color-white);
    padding: var(--card-padding);
    border-radius: var(--card-border-radius);
    margin-top: 1rem;
    box-shadow: var(--box-shadow);
    transition: all 300ms ease;
}

main .insights > div:hover {
    box-shadow: none;
}

main .insights > div span {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    color: var(--color-white);
    background: var(--color-primary);
    font-size: 2rem;
}

main .insights > div.expenses span {
    background: var(--color-danger);
}

main .insights > div.income span {
    background: var(--color-success);
}

main .insights > div .middle {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

main .insights h3 {
    margin: 1rem 0 0.6rem;
    font-size: 1rem;
}

main .insights .progress {
    position: relative;
    width: 92px;
    height: 92px;
    border-radius: 50%;
}

main .insights .circular-progress-container {
    position: relative;
    width: 92px;
    height: 92px;
    border-radius: 50%;
    top: -9px;
    margin-left: auto;
}

main .insights .progress svg {
    width: 7rem;
    height: 7rem;
}

main .insights .progress svg circle {
    fill: none;
    stroke: var(--color-primary);
    stroke-width: 14;
    stroke-linecap: round;
    transform: translate(5px, 5px);
    stroke-dasharray: 110;
    stroke-dashoffset: 92;
}

main .insights .sales .progress svg circle {
    stroke-dashoffset: -30;
    stroke-dasharray: 200;
}

main .insights .expenses .progress svg circle {
    stroke-dashoffset: 20;
    stroke-dasharray: 80;
}

main .insights .progress .number {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

main .insights small {
    margin-top: 1.3rem;
    display: flex;
}

main .recent-order {
    margin-top: 2rem;
}

main .recent-order h2 {
    margin-bottom: 0.8rem;
}

main .recent-order table {
    background: var(--color-white);
    width: 100%;
    border-radius: var(--card-border-radius);
    padding: var(--card-padding);
    text-align: center;
    box-shadow: var(--box-shadow);
    transition: all 300ms ease;
}

main .recent-order table:hover {
    box-shadow: none;
}

main table tbody td {
    height: 2.8rem;
    border-bottom: 1px solid var(--color-light);
    color: var(--color-dark-variant);
}

main table tbody tr:last-child {
    border: none;
}

main .recent-order a {
    text-align: center;
    display: block;
    margin: 1rem auto;
    color: var(--color-primary);
}

.profile-photo {
    width: 2.8rem;
    height: 2.8rem;
    border-radius: 50%;
    overflow: hidden;
}

.profile-photo img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.right {
    margin-top: 1.4rem;
    width: 100%;
}

.right .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    padding: 1rem;
}

.navbar-container {
    width: 100%;
    background-color: var(--color-background);
    box-shadow: var(--box-shadow);
    padding: 0.5rem 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.navbar-top {
    display: flex;
    align-items: center;
    gap: 1rem;
    width: 100%;
}

.navbar-right {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-left: auto;
}

.notification-icon {
    position: relative;
    cursor: pointer;
}

.notification-bell:hover {
    color: red;
    animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 1000px;
}

@keyframes shake {
    10%, 90% {
        transform: translate3d(-1px, 0, 0);
    }

    20%, 80% {
        transform: translate3d(2px, 0, 0);
    }

    30%, 50%, 70% {
        transform: translate3d(-4px, 0, 0);
    }

    40%, 60% {
        transform: translate3d(4px, 0, 0);
    }
}

.notification-count {
    position: absolute;
    top: -10px;
    right: -10px;
    background-color: red;
    color: white;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
}

.notifications-dropdown {
    position: absolute;
    top: 40px;
    right: 0;
    background-color: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: 300px;
    max-height: 200px;
    overflow-y: auto;
    z-index: 1000;
}

.notification-item {
    padding: 10px;
    border-bottom: 1px solid #f0f0f0;
}

.notification-item.unseen {
    background-color: #e6f7ff;
}

.notification-separator {
    border: none;
    height: 1px;
    background-color: #f0f0f0;
    margin: 5px 0;
}

.notification-scroll {
    text-align: center;
    padding: 5px;
    font-style: italic;
}

.notification-item:last-child {
    border-bottom: none;
}

.theme-toggler {
    background: var(--color-light);
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 1.6rem;
    width: 4.2rem;
    cursor: pointer;
    border-radius: var(--border-radius-1);
}

.theme-toggler span {
    font-size: 1.2rem;
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.theme-toggler span.active {
    background: var(--color-primary);
    color: white;
    border-radius: var(--border-radius-1);
}

.profile {
    display: flex;
    gap: 1rem;
    align-items: center;
}

.profile .info {
    display: none;
}
.dashboard-layout {
    display: flex;
    align-items: flex-start;
    gap: 20px;
    min-width: 85vw;
    padding: 20px;
    box-sizing: border-box;
    overflow-x: auto;
}
.left-dashboard .recent-updates{
    margin-top: 4%;
}
.left-dashboard .recent-updates .updates{
    background: var(--color-white);
    padding: var(--card-padding);
    border-radius: var(--card-border-radius);
    margin-top: 1rem;
    box-shadow: var(--box-shadow);
    transition: all 300ms ease;
    width: 90%;
}


@media screen and (min-width: 768px) {
    .profile .info {
        display: block;
    }
}

@media screen and (min-width: 1200px) {
    .sidebar-toggle-btn {
        display: none;
    }
}

@media screen and (max-width: 1200px) {
    main .insights {
        grid-template-columns: 1fr;
        gap: 0;
    }
    
    main .recent-order {
        width: 94%;
        position: relative;
        left: 10%;
        transform: translateX(-50%);
        margin: 1rem 0 0 14rem;
    }
    .dashboard-layout {
        display: block;
    }
    main .recent-order h2{
        margin-left: 10%;
    }
    main .recent-order a {
        margin-left: 20%;
    }
    main .recent-order table {
        width: 106%;
        margin-left: 5rem;
    }

    

    main table thead tr th:last-child,
    main table thead tr th:first-child {
        display: none;
    }

    main table tbody tr td:last-child,
    main table tbody tr td:first-child {
        display: none;
    }
}

@media screen and (max-width: 768px) {
    main {
        margin-top: 8rem;
        padding: 0 1rem;
    }
    
    main .recent-order {
        position: relative;
        margin: 3rem 0 0 0;
    }
    main .recent-order h2{
        margin-left: 40%;
    }
    main .recent-order table {
        width: 106%;
        margin-left: 40%;
    }
    main .recent-order a {
        margin-left: 80%;
    }
    .right {
        width: 94%;
        margin: 0 auto 4rem;
    }

    .right .top {
        position: fixed;
        top: 0;
        left: 0;
        align-items: center;
        padding: 0 0.8rem;
        height: 4.6rem;
        background: var(--color-white);
        width: 100%;
        margin: 0;
        z-index: 2;
        box-shadow: 0 1rem 1rem var(--color-light);
    }

    .right .top .theme-toggler {
        width: 4.4rem;
        position: absolute;
        left: 66%;
    }

    .right .profile .info {
        display: none;
    }

    .right .top button {
        display: inline-block;
        background: transparent;
        cursor: pointer;
        color: var(--color-dark);
        position: absolute;
        left: 1rem;
    }

    .right .top button span {
        font-size: 2rem;
    }

    .dashboard-layout {
        flex-direction: column;
    }
}

@media screen and (max-width: 480px) {
    main {
        margin-top: 5rem;
        padding: 0 0.5rem;
    }

    main .recent-order {
        position: relative;
        margin: 1rem 0 0 0;
        width: 100%;
    }
    
    main .recent-order table {
        width: 100%;
        margin-left: 40%;
    }
    .right {
        width: 100%;
        margin: 0 auto 2rem;
    }

    .right .top {
        height: 3.6rem;
        padding: 0 0.5rem;
    }

    .right .top button span {
        font-size: 1.5rem;
    }
}

