.request-forms-container {
    padding: 40px;
    width: 100%;
    margin: 0 auto;
    background-color: var(--color-background);
    border-radius: var(--card-border-radius);
    box-shadow: var(--box-shadow);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.header-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 20px;
}

.header-container h1 {
    color: var(--color-dark);
    margin-bottom: 20px;
}

.form-dropdown {
    width: 100%;
    padding: 12px;
    border: 1px solid var(--color-info-dark);
    border-radius: var(--border-radius-1);
    font-size: 16px;
    color: var(--color-dark-variant);
    background-color: var(--color-white);
    transition: border-color 0.3s;
    margin-bottom: 20px;
}

.form-dropdown:focus {
    border-color: var(--color-primary);
    outline: none;
}

.form-details {
    width: 100%;
    color: var(--color-dark);
}

.form-details h2 {
    color: var(--color-primary);
    margin-bottom: 20px;
    text-align: center;
}

.request-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.form-group {
    display: flex;
    flex-direction: column;
}

.form-group label {
    margin-bottom: 5px;
    color: var(--color-dark);
    font-weight: bold;
}

.textarea-input {
    width: 100%;
    padding: 12px;
    border: 1px solid var(--color-info-dark);
    border-radius: var(--border-radius-1);
    font-size: 16px;
    color: var(--color-dark-variant);
    background-color: var(--color-white);
    transition: border-color 0.3s;
    resize: vertical;
    min-height: 100px;
}

.textarea-input:focus {
    border-color: var(--color-primary);
    outline: none;
}

.checkbox-group {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    gap: 10px;
}

.checkbox-group label {
    display: flex;
    align-items: center;
    color: var(--color-dark);
}

.checkbox-group input[type="checkbox"],
.checkbox-group input[type="radio"] {
    margin-right: 10px;
}

.submit-button {
    padding: 12px 20px;
    border: none;
    border-radius: var(--border-radius-1);
    background-color: var(--color-primary);
    color: var(--color-white);
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
    align-self: center;
}

.submit-button:hover {
    background-color: var(--color-primary-variant);
}

.error-text {
    color: red;
    margin-top: -10px;
    margin-bottom: 10px;
}

.visa-note,
.google-form-note {
    margin-top: 10px;
    font-size: 14px;
    color: var(--color-dark-variant);
}

.visa-note a,
.google-form-note a {
    color: var(--color-primary);
    text-decoration: underline;
}

.note {
    color: red;
    margin-top: 20px;
    font-size: 14px;
}

.note p {
    margin: 0;
}

.bold-text {
    font-weight: bold;
    color: var(--color-dark);
}

.resignation-note {
    color: var(--color-dark-variant);
    font-size: 14px;
}

.funeral-note {
    color: var(--color-dark-variant);
    font-size: 14px;
}

.download-button-Leave {
    padding: 12px 20px;
    border: none;
    border-radius: var(--border-radius-1);
    background-color: var(--color-primary);
    color: var(--color-white);
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
    align-self: center;
}

.download-button-Leave:hover {
    background-color: var(--color-primary-variant);
}

.file-upload-group {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
}

.file-upload-group label {
    margin-bottom: 5px;
    color: var(--color-dark);
    font-weight: bold;
}

.file-upload-group input[type="file"] {
    border: 1px solid var(--color-info-dark);
    border-radius: var(--border-radius-1);
    padding: 10px;
    color: var(--color-dark-variant);
    background-color: var(--color-white);
}
