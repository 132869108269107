.information-center {
    background-color: var(--color-background);
    color: var(--color-dark);
    min-height: 100vh;
    padding: var(--padding-1);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.top-bar {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    flex-wrap: wrap; /* Added to wrap items in smaller screens */
}

.buttons-container {
    display: flex;
    flex-wrap: wrap; /* Added to wrap buttons in smaller screens */
    gap: 1rem; /* Added space between buttons */
}

.info-button-wrapper {
    position: relative;
}

.edit-buttons {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap; /* Added to wrap buttons in smaller screens */
    gap: 1rem; /* Added space between buttons */
}

.info-button {
    background-color: var(--color-primary);
    color: var(--color-white);
    border: none;
    padding: 0.8rem 1.6rem;
    cursor: pointer;
    border-radius: var(--border-radius-1);
    box-shadow: var(--box-shadow);
    transition: background-color 0.3s;
    position: relative;
    flex: 1 1 auto; /* Allow buttons to resize */
    min-width: 180px; /* Minimum width for buttons */
}

.info-button.active, .info-button:hover {
    background-color: var(--color-primary-variant);
}

.files-container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding: 1rem;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease-in-out;
    position: relative;
    top: -10px;
    background-color: var(--color-info-light);
    border-radius: var(--border-radius-2);
    box-shadow: var(--box-shadow);
}

.files-container.show {
    opacity: 1;
    visibility: visible;
    top: 0;
}

.file-card-link {
    text-decoration: none;
    color: inherit;
}

.edit-filename-input {
    width: 100%;
    border: 1px solid var(--color-info-dark);
    border-radius: var(--border-radius-1);
    padding: 0.5rem;
    font-size: 1rem;
}

.file-card {
    background-color: var(--color-white);
    width: 200px;
    height: 200px;
    margin: 1rem;
    border-radius: var(--border-radius-1);
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: var(--box-shadow);
    transition: transform 0.3s, box-shadow 0.3s;
}

.file-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.new-category-svg {
    position: absolute;
    top: -16px;
    right: 0px;
    width: 37px;
    height: 30px;
    z-index: 10;
}

.file-thumbnail-wrapper {
    width: 100%;
    height: 60%; /* Adjust height to allocate more space for the thumbnail */
    position: relative;
}

.file-thumbnail {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
}

.file-info {
    width: 100%;
    height: 40%; /* Adjust height to allocate more space for the file name */
    padding: 0.5rem;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    white-space: normal;
    word-break: break-all;
    overflow: hidden;
}

.new-file-svg {
    position: absolute;
    top: -28px;
    right: -22px;
    width: 50px;
    height: 30px;
    z-index: 10;
}

.file-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
}

.delete-button {
    background-color: var(--color-danger);
    color: var(--color-white);
    border: none;
    padding: 0.5rem;
    cursor: pointer;
    margin-top: 0.5rem;
}

.edit-button {
    background-color: var(--color-info-dark);
    color: var(--color-white);
    border: none;
    padding: 0.5rem 1rem;
    cursor: pointer;
    border-radius: var(--border-radius-1);
    margin-left: 1rem;
}

.modal-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
}

.modal-content {
    background-color: var(--color-white);
    padding: 2rem;
    border-radius: var(--border-radius-2);
    box-shadow: var(--box-shadow);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.modal-content button {
    margin-top: 1rem;
    padding: 0.5rem 1rem;
    border: none;
    cursor: pointer;
    border-radius: var(--border-radius-1);
}

.modal-content button:first-child {
    background-color: var(--color-danger);
    color: var(--color-white);
}

.modal-content button:last-child {
    background-color: var(--color-primary);
    color: var(--color-white);
}

@media screen and (max-width: 768px) {
    .files-container {
        padding: 0.5rem;
    }

    .file-card {
        width: 150px;
        height: 150px;
    }

    .file-info {
        padding: 0.3rem;
    }

    .file-thumbnail-wrapper {
        height: 55%;
    }

    .file-info {
        height: 45%;
    }

    .edit-buttons {
        display: block; /* Change to block for stacking vertically */
    }

    .info-button {
        margin-right: 0;
        margin-bottom: 0.5rem;
    }

    .edit-button {
        margin-left: 0;
        margin-bottom: 0.5rem;
    }

    .edit-buttons .edit-button + .edit-button {
        margin-left: 10px; /* Ensure no margin-left between stacked buttons */
        margin-top: 0.5rem; /* Add margin-top between stacked buttons */
    }
    .files-container.show {
        justify-content: center;
    }
}
