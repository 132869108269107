/* client/src/styles/AddBusiness.css */
.add-business {
    background-color: var(--color-background);
    padding: var(--card-padding);
    border-radius: var(--card-border-radius);
    box-shadow: var(--box-shadow);
    margin-bottom: 30px;
    overflow: hidden;
    width: 100%;
}

.form-container {
    display: grid;
    grid-gap: 10px;
    padding: 10px;
}

.business-item {
    grid-area: auto;
}

.position-item {
    display: grid;
    grid-gap: 10px;
    padding: 10px;
}

/* Adjust as needed for individual input items, similar to your ProjectAssignment.css */
.input-item {
    width: 95%;
    padding: 0.5rem;
    border-radius: var(--border-radius-1);
    border: 1px solid var(--color-info-light);
    background-color: var(--color-white);
    margin-bottom: 0.5rem;
}

.button {
    background-color: var(--color-primary);
    color: var(--color-white);
    padding: 0.5rem 1rem;
    border-radius: var(--card-border-radius);
    cursor: pointer;
    border: none;
    transition: background-color 0.3s;
    width: 100%;
    margin-bottom: 1rem;
}

.button:hover {
    background-color: var(--color-primary-variant);
}

.position-input {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.5rem;
}

.position-input input {
    flex-grow: 1;
    margin-right: 0.5rem;
    width: 90%;
    padding: 0.5rem;
    border-radius: var(--border-radius-1);
    border: 1px solid var(--color-info-light);
    background-color: var(--color-white);
    margin-bottom: 0.5rem; /* Space between input and remove button */
}

.position-input button,
.add-position-btn {
    background-color: var(--color-primary);
    color: var(--color-white);
    padding: 0.5rem 1rem;
    border-radius: var(--card-border-radius);
    cursor: pointer;
    border: none;
    transition: background-color 0.3s;
    text-align: center; /* Center text inside the button */
    width: auto; /* Adjust width to content */
    display: inline-block; /* Align with input */
    margin: 0; /* Remove margin for inline alignment */
}

.add-position-btn {
    display: block; /* Center the button below inputs */
    margin: 1rem auto; /* Centering margin */
}

/* Align "Remove" button with "Add Position" styling */
.position-input button:hover,
.add-position-btn:hover {
    background-color: var(--color-primary-variant);
}

/* You can add more specific styles as needed, following the structure from ProjectAssignment.css */