/* client/src/styles/Login.css */

.login-container {
  display: flex;
  align-items: center; /* Centers vertically */
  justify-content: center; /* Centers horizontally */
  height: 100vh;
  width: 100vw;
  background: url('../assets/Background.png') no-repeat center center fixed;
  background-size: cover;
  position: relative; /* Ensure relative positioning for the container */
}

.login-card {
  position: relative; /* Relative positioning to contain the pseudo-element */
  padding: 2rem;
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%; /* Default width for small screens */
  max-width: 350px; /* Max width for larger screens */
  z-index: 1; /* Ensure the card is above the pseudo-element */
}

.login-logo {
  background: url('../assets/Brites_logo.png') no-repeat center center;
  background-size: contain;
  height: 100px;
  width: 100%;
  margin-bottom: 1rem;
}

.login-form {
  width: 100%;
}

.login-form input {
  width: 100%;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.login-form button {
  width: 100%;
  padding: 0.5rem;
  border: none;
  border-radius: 4px;
  background-color: #00f;
  color: white;
  cursor: pointer;
}

.login-form button:hover {
  background-color: #00d;
}

/* Media queries for larger screens */
@media (min-width: 1024px) {
  .login-container {
    background-position: center;
    background-attachment: fixed;
  }

  .login-card {
    width: 350px;
  }

  .login-card::before {
    content: "";
    position: absolute;
    left: -200px;
    top: 0;
    bottom: 0;
    width: 200px; /* Set the width of your side image */
    background: url('../assets/Login_side_backgroung.png') no-repeat center center;
    background-size: cover;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    z-index: -1; /* Ensure the pseudo-element is below the card */
  }
}
