.leave-approval-page {
  padding: 20px;
}

.search-filter-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
}

.search-input,
.status-filter {
  width: 100%;
}

.leave-cards-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.leave-card {
  border: 1px solid #e0e0e0;
  padding: 20px;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s, box-shadow 0.3s;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.leave-card:hover {
  background-color: #f5f5f5;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.leave-card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.leave-card-header h3 {
  font-size: 1.2rem;
  font-weight: bold;
}

.leave-card-body {
  margin-bottom: 10px;
}

.leave-card-footer {
  display: flex;
  justify-content: flex-end;
}

.status {
  padding: 5px 10px;
  border-radius: 5px;
  color: white;
}

.status.อนุมัติ {
  background-color: #4caf50;
}

.status.รออนุมัติ {
  background-color: #ff9800;
}

.status.ไม่อนุมัติ {
  background-color: #f44336;
}

.status.ยกเลิก {
  background-color: #9e9e9e;
}

.leave-detail-modal .ant-modal-content {
  width: 90%;
  max-width: 1000px;
}

.leave-detail-card {
  text-align: left;
  padding: 20px;
}

.leave-detail-card h2 {
  text-align: center;
}

.leave-detail-card p {
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
}

.leave-detail-card p span {
  font-weight: bold;
}

.leave-condition {
  color: red;
}

.ant-pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.download-button {
  background-color: #1890ff;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  text-decoration: none;
  display: inline-block;
  margin-top: 10px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.download-button:hover {
  background-color: #40a9ff;
}

@media (min-width: 768px) {
  .search-filter-container {
    flex-direction: row;
    justify-content: space-between;
  }

  .search-input,
  .status-filter {
    width: 200px;
  }
}

@media (max-width: 480px) {
  .leave-card-header h3 {
    font-size: 1rem;
  }

  .leave-detail-card p {
    flex-direction: column;
    align-items: flex-start;
  }
  /* .leave-card{
    width: 90%;
  } */
}
