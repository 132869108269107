.profile-page {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
  background-color: var(--color-background);
  padding: var(--card-padding);
  border-radius: var(--card-border-radius);
  box-shadow: var(--box-shadow);
  overflow: hidden;
  width: 100%;
}
.timesheet-button {
  background-color: var(--color-primary);
  color: white;
  padding: 10px 20px;
}


.timesheet-form label {
  display: block;
  margin-bottom: 8px;
  font-weight: bold;
}

.timesheet-form input,
.timesheet-form .task-dropdown {
  width: 100%;
  margin-bottom: 20px;
  padding: 8px;
}

/* .timesheet-form .task-dropdown {
  border-radius: 4px;
} */

.timesheet-form .react-datepicker-wrapper {
  width: 100%;
}
.user-info-and-action {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 20px;
}

.user-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.profile-image-wrapper {
  position: relative;
  display: inline-block;
  cursor: pointer;
}
.timesheet-form .ant-select-selector {
  width: 100%; /* Keep it full width inside the form */
  padding: 8px;
  border-radius: 4px;
  min-width: 200px; /* Adjust this value as needed to make the dropdown wider initially */
}

.profile-image-wrapper:hover .profile-image {
  filter: brightness(70%);
}

.edit-icon {
  position: absolute;
  top: 0;
  right: 0;
  color: white;
  font-size: 1.5rem;
  display: none;
}

.profile-image-wrapper:hover .edit-icon {
  display: block;
  width: 110px;
  height: 30px;
  margin-top: 40px;
  color: #f0f8ff;
}

.profile-image {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 1rem;
}

.user-info h1 {
  font-size: 1.5rem;
  margin-bottom: 0.25rem;
}

.leave-request-button,
.reset-password-button {
  width: 200px;
  margin-bottom: 10px;
  cursor: pointer;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  background-color: var(--color-primary);
  color: var(--color-white);
  font-weight: bold;
}

.leave-request-button {
  background-color: var(--color-primary);
}

.reset-password-button {
  background-color: #28a745;
}

.leave-status-section {
  margin-bottom: 20px;
}

.leave-requests-list {
  max-height: 150px;
  overflow-y: auto;
}

.leave-request-card {
  background-color: var(--color-white);
  border-radius: var(--border-radius-1);
  padding: var(--card-padding);
  box-shadow: var(--box-shadow);
}

.leave-request-card button {
  padding: 5px 10px;
  background-color: var(--color-danger);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 1rem;
}

.flex-container {
  display: flex;
  flex-direction: column; /* Stack vertically for small screens */
  width: 100%;
}

.projects-participant-section,
.leave-statistics-section {
  width: 100%;
  margin-bottom: 20px;
}

.projects-list {
  max-height: 400px;
  overflow-y: auto;
  scrollbar-width: none;
}

.projects-list::-webkit-scrollbar {
  display: none;
}

.project-card {
  background-color: var(--color-white);
  border-radius: var(--border-radius-1);
  padding: var(--card-padding);
  margin-bottom: 1rem;
  box-shadow: var(--box-shadow);
  transition: all 0.3s ease;
}

.project-card h3 {
  color: var(--color-primary);
  margin-bottom: 1rem;
  position: relative;
  padding-bottom: 0.25rem;
}

.project-card h3::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: var(--color-primary);
  border-radius: 1px;
}

.task-details {
  display: flex;
  flex-direction: column;
  margin-bottom: 0.25rem;
}

.task-description,
.task-position,
.task-status {
  color: var(--color-info-dark);
}

.task-position {
  font-weight: bold;
}

.project-card:hover {
  box-shadow: none;
}

h2 {
  margin-bottom: 1rem;
  color: var(--color-dark);
}

h3 {
  color: var(--color-primary);
}

p {
  color: var(--color-info-dark);
}

.leave-form-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(5px);
  z-index: 1000;
}

.leave-form-container {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 90%;
  max-width: 500px;
  max-height: 80vh;
  overflow-y: auto;
}

.leave-form-container::-webkit-scrollbar {
  display: none;
}

.leave-form {
  display: flex;
  flex-direction: column;
}

.form-field {
  margin-bottom: 1rem;
}

.form-field label {
  display: block;
  margin-bottom: 0.5rem;
}

.form-field input[type="text"],
.form-field select,
.form-field textarea {
  width: 100%;
  padding: 0.5rem;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.form-actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
  flex-direction: column;
  align-items: flex-start;
}

.form-actions button {
  padding: 0.5rem 1rem;
  border-radius: 5px;
  border: none;
  margin-left: 0.5rem;
  cursor: pointer;
}

.leave-request-button {
  padding: 0.5rem 1rem;
  background-color: #007bff;
  color: white;
  border-radius: 5px;
  border: none;
  cursor: pointer;
}

.leave-form h2 {
  margin-bottom: 20px;
}

.leave-form label {
  display: block;
  margin-bottom: 5px;
}

.leave-form input,
.leave-form textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.leave-form textarea {
  resize: vertical;
}

.form-actions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.form-actions button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  color: #fff;
}

.form-actions button[type="submit"] {
  background-color: var(--color-success);
}

.form-actions button[type="button"] {
  background-color: var(--color-danger);
}

.reset-password-form-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.reset-password-form-container {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 90%;
  max-width: 400px;
}

.reset-password-form {
  display: flex;
  flex-direction: column;
}

.reset-password-form h2 {
  text-align: center;
  margin-bottom: 20px;
}

.form-field {
  margin-bottom: 15px;
}

.form-field label {
  display: block;
  margin-bottom: 5px;
}

.form-field input[type="password"] {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.form-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.form-actions button {
  cursor: pointer;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  color: #fff;
  background-color: #007bff;
  transition: background-color 0.3s;
}

.form-actions button:hover {
  background-color: #0056b3;
}

.form-actions button[type="button"] {
  background-color: #6c757d;
}

.form-actions button[type="button"]:hover {
  background-color: #545b62;
}

.reset-password-button {
  width: 200px;
  margin-left: 1.2rem;
  padding: 7px 10px;
  background-color: #28a745;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.reset-password-button:hover {
  background-color: #218838;
}

.terms-container {
  display: flex;
  align-items: flex-start;
  align-items: center;
  margin-bottom: 1rem;
}

.terms-container input[type="checkbox"] {
  margin-right: 0.5rem;
  width: 10%;
}

.terms-text {
  color: red;
  font-size: 0.875rem;
}

.action-buttons {
  display: flex;
  gap: 1rem;
  justify-content: flex-start;
  align-items: center;
}

.ant-select-dropdown {
  min-width: 300px; /* Increase this value as needed */
}

.ant-select-selector {
  width: 100%; /* Ensure the input is full width in its container */
  min-width: 300px; /* Set the minimum width for the dropdown */
  padding: 8px;
}

.ant-table-expanded-row {
  width: 100%; /* Make sure the expanded row takes the full width */
}

.ant-table-tbody > tr > td {
  padding: 12px; /* Increase padding for empty cells */
}

.empty-expanded-row {
  width: 100%;
  text-align: center;
}
@media screen and (min-width: 1200px) {
  .user-info-and-action {
    flex-direction: row;
  }

  .user-info {
    width: 50%;
  }

  .leave-status-section {
    width: 50%;
    margin-left: 20px;
  }

  .flex-container {
    flex-direction: row;
  }

  .projects-participant-section,
  .leave-statistics-section {
    width: 50%;
    margin: 0 10px;
  }

  .leave-statistics-section .ant-row {
    flex-direction: row;
  }

  .ant-col {
    width: 33.33% !important;
  }
}

@media screen and (max-width: 1200px) {
  .user-info-and-action {
    flex-direction: column;
  }

  .user-info {
    width: 100%;
    margin-bottom: 20px;
  }

  .leave-status-section {
    width: 100%;
    margin-bottom: 20px;
  }

  .reset-password-button {
    width: 100%;
    margin-left: 0;
    margin-bottom: 20px;
  }

  .leave-requests-list {
    max-height: 150px;
    overflow-y: auto;
  }

  .flex-container {
    flex-direction: column;
  }

  .projects-participant-section,
  .leave-statistics-section {
    width: 100%;
    margin-bottom: 20px;
  }

  .ant-col {
    width: 100% !important;
  }
  .leave-request-button {
    width: 100%;

  }
  .leave-statistics-section .ant-row {
    flex-direction: column;
    width: 300%;
  }
}
@media screen and (max-width: 600px) {
  .ant-table {
    font-size: 12px; /* Smaller font size */
    margin: 0; /* Reduce margins */
    padding: 8px; /* Adjust padding */
    overflow-x: auto; /* Allow horizontal scroll */
  }

  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    padding: 2px 4px; /* Reduce table cell padding */
    white-space: nowrap; /* Prevent table content from wrapping */
  }

  .ant-btn {
    font-size: 12px; /* Smaller button font size */
    padding: 4px 8px; /* Adjust button padding */
  }

  .action-buttons {
    flex-direction: column;
    gap: 5px;
  }
}

/* For screens smaller than 400px */
@media screen and (max-width: 450px) {
  /* Target Ant Design table header and body cells specifically */
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    padding: 17px 6px !important; /* Increase cell padding for touch devices */
    font-size: 9px !important; /* Smaller font size for table content */
    white-space: nowrap !important; /* Prevent text wrapping */
  }

  .ant-table {
    font-size: 10px !important; /* Reduce font size */
    padding: 6px !important; /* Slightly larger padding for usability */
    margin: 0 8px !important; /* Add some margin to give space */
    width: calc(100% - 16px) !important; /* Ensure full width with margins */
    overflow-x: scroll !important; /* Ensure horizontal scrolling */
  }

  .ant-btn {
    font-size: 10px !important; /* Small font size for buttons */
    padding: 4px 6px !important; /* Adjust button padding for small screens */
  }

  .action-buttons {
    flex-direction: column; /* Stack action buttons vertically */
    gap: 5px;
  }
  .profile-page {
    padding: 10px !important; /* Decrease outer page padding */
  }
  .ant-input {
    font-size: 10px !important; /* Even smaller font for very small screens */
    padding: 5px !important;
  }

  .ant-select-single .ant-select-selector .ant-select-selection-item, 
  .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    font-size: 12px !important; /* Smaller font size for select dropdown text */
    padding: 5px !important;
  }
  

}

@media screen and (max-width: 768px) {
  .ant-table {
    width: 100% !important; /* Ensure the table takes full width */
  }
  
  .ant-table-tbody > tr > td {
    white-space: nowrap !important;
    text-align: center;
  }
}