.leave-page-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  gap: 20px;
}

.top-bar-state {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.leave-calendar-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  height: 83vh;
  width: 100%;
  gap: 20px;
}

.Calendar {
  flex-grow: 3;
  width: 100%;
  height: 100%;
}

.user-list-container {
  flex-grow: 1;
  width: 30%;
  display: flex;
  flex-direction: column;
  height: 100%;
  transition: transform 0.3s ease;
}

.user-list-container.hide {
  display: none;
}

.user-list-container.show {
  display: flex;
}

.search-box {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.user-list {
  overflow-y: auto;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  flex-grow: 1;
}

.user-item {
  cursor: pointer;
  padding: 10px;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.user-item:hover {
  background-color: #f0f0f0;
}

.user-item.selected {
  font-weight: bold;
  background-color: #e7e7e7;
  color: #fff;
}

.profile-pic {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  object-fit: cover;
}

.download-report-button {
  padding: 10px 20px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
  width: 100%;
  text-align: center;
}

.download-report-button:hover {
  background-color: #45a049;
}

.toggle-view-button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.toggle-view-button:hover {
  background-color: #0056b3;
}

.toggle-user-list-button {
  margin-left: 20px;
  padding: 4px 10px;
  background-color: 	#46b834;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.toggle-user-list-button:hover {
  background-color: #2b921c;
}

@media (max-width: 768px) {
  .leave-calendar-container {
    flex-direction: column;
  }

  .user-list-container {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    z-index: 10;
    background: white;
  }

  .Calendar {
    width: 100%;
  }

  .toggle-user-list-button {
    display: block;
  }
  .user-list-container.show {
    margin-top: 11rem;
    height: 84%;
  }
  .top-bar-state {
    gap: 1rem;
  }
  .download-report-button {
    display: none;
  }
}

@media (min-width: 769px) {
  .toggle-user-list-button {
    display: block;
  }
}

@media screen and (max-width: 480px) {
  .leave-calendar-container {
    flex-direction: column;
  }

  .user-list-container {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    z-index: 10;
    background: white;
  }

  .Calendar {
    width: 100%;
  }

  .toggle-user-list-button {
    display: block;
    width: 14rem;
  }
  .download-report-button {
    display: none;
  }
}
