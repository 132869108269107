@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800&family=Source+Sans+3:wght@400;600;700&display=swap');

:root {
    --color-primary:#7380ec;
    --color-danger:#ff7782;
    --color-success:#41f1b6;
    --color-warning:#ffbb55;
    --color-white:#fff;
    --color-info-dark:#7d8da1;
    --color-info-light:#dce1eb;
    --color-dark:#363949;
    --color-light:rgba(132,139,200,0.18);
    --color-primary-variant:#111e88;
    --color-dark-variant:#677483;
    --color-background:#f6f6f9;

    --card-border-radius: 2rem;
    --border-radius-1: 0.4rem;
    --border-radius-2: 0.8rem;
    --border-radius-3: 1.2rem;

    --card-padding: 1.8rem;
    --padding-1: 1.2rem;

    --box-shadow: 0 2rem 3rem var(--color-light);
}

.dark-theme-variables {
    --color-background: #181a1e;
    --color-white:#202528;
    --color-dark:#edeffd;
    --color-dark-variant:#a3bdcc;
    --color-light:rgba(0,0,0,0.4);
    --box-shadow:0 2rem 3rem var(--color-light);
}
*{
    margin: 0;
    padding: 0;
    outline: 0;
    /* appearance: none; */
    border: 0;
    text-decoration: none;
    list-style: none;
    box-sizing: border-box;
}

html {
    font-size: 14px;
}

body {
    width: 100%;
    height: 100%;
    font-family: poppins,sans-serif;
    font-size: 0.88rem;
    background: var(--color-background);
    user-select:none;
    overflow-x: hidden;
}

.container {
    display: flex;
    flex-direction: row; 
    align-items: flex-start;
    gap: 20px; 
    padding: 20px; 
    overflow-x: auto; 
    max-width: 100%; 
    width: 100%;
    
  }
.dashboard-page {
    display: flex;
    min-height: 100vh;
  }
  
.main-content {
flex-grow: 1; /* Take up remaining space */
display: flex;
flex-direction: column;
}

a {
    color:var(--color-dark)
}

img {
    display: block;
    width: 58%;
}

h1{
    font-weight: 800;
    font-size: 1.8rem;
}

h2 {
    font-size: 1.4rem;
}

h3 {
    font-size: 0.87rem;
}

h4 {
    font-size: 0.8rem;
}

h4 {
    font-size: 0.77rem;
}

small {
    font-size: 0.75rem;
}

.profile-photo {
    width: 2.8rem;
    height: 2.8rem;
    border-radius: 50%;
    overflow: hidden;
}

.text-muted {
    color: var(--color-info-dark)
}

p {
    color: var(--color-dark-variant);
}

b {
    color: var(--color-dark);
}

.primary {
    color: var(--color-primary);
}

.danger {
    color: var(--color-danger);
}

.success {
    color: var(--color-success);
}

.warning {
    color: var(--color-warning);
}

.status.done {
    color: var(--color-success); 
    font-weight: bold;
  }
  
  .status.not-done {
    color: var(--color-warning);
    font-weight: bold;
  }
  
  .status.problem {
    color: var(--color-danger);
    font-weight: bold;
  }

  .icon-wrapper {
    display: flex;
    align-items: center;
  }
  
  .sidebar-icon {
    width: 25px;
    height: 25px;
    filter: brightness(0) saturate(100%) invert(38%) sepia(3%) saturate(215%) hue-rotate(172deg) brightness(90%) contrast(92%);
    transition: filter 0.3s, color 0.3s;
  }
  
  .sidebar a:hover .sidebar-icon,
  .sidebar a.active .sidebar-icon {
    filter: brightness(0) saturate(100%) invert(64%) sepia(60%) saturate(338%) hue-rotate(185deg) brightness(60%) contrast(91%);
  }
aside {
    height: 100vh;
    width: 243px;
    /* background: gray; */
}

aside .top{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 1.4rem;
}

aside .close {
    display: none;
}

/*==================================== SIDEBAR =====================================*/

aside .sidebar{
    /* background: white; */
    display: flex;
    flex-direction: column;
    height: 86vh;
    position: relative;
    top: 3rem;
}

aside h3 {
    font-weight: 500;
}
aside .logo {
    
    display: flex;
    align-items: center;
    justify-content: space-between;
}

aside .logo img{
    width: 177px;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 1.4rem;
    margin-left: 1.4rem;
}
aside .sidebar a {
    display: flex;
    color: var(--color-info-dark);
    margin-left: 2rem;
    gap: 1rem;
    align-items: center;
    position: relative;
    height: 3.7rem;
    transition: all 300ms ease;
}

aside .sidebar a span{
    font-size: 1.6rem;
    transition:  all 300ms ease;
}

/* aside .sidebar a:last-child {
    position: absolute;
    bottom: 2rem;
    width: 100%;
} */

aside .sidebar a.active {
    background: var(--color-light);
    color: var(--color-primary);
    margin-left: 0;
}

aside .sidebar a.active::before {
    content: '';
    width: 6px;
    height: 100%;
    background: var(--color-primary);
}

aside .sidebar a.active span{
    color: var(--color-primary);
    margin-left: calc(1rem - 3px);
}

aside .sidebar a:hover{
    color: var(--color-primary);
}

aside .sidebar a:hover span {
    margin-left: 1rem;
}

aside .sidebar .message-count {
    background: var(--color-danger);
    color: var(--color-white);
    padding:  2px 10px;
    font-size: 11px;
    border-radius: var(--border-radius-1);
}
/* NavBar.css */
/* NavBar.css */
.notification-icon {
    position: relative;
    cursor: pointer;
  }
  
  .notification-bell:hover {
    color: red; /* Change to your preferred hover color */
    animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 1000px;
}

@keyframes shake {
    10%, 90% {
        transform: translate3d(-1px, 0, 0);
    }

    20%, 80% {
        transform: translate3d(2px, 0, 0);
    }

    30%, 50%, 70% {
        transform: translate3d(-4px, 0, 0);
    }

    40%, 60% {
        transform: translate3d(4px, 0, 0);
    }
}

.notification-count {
    position: absolute;
    top: -10px; /* Adjust as needed */
    right: -10px; /* Adjust as needed */
    background-color: red; /* Adjust as needed */
    color: white;
    border-radius: 50%;
    width: 20px; /* Adjust as needed */
    height: 20px; /* Adjust as needed */
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
}
  
.notifications-dropdown {
    position: absolute;
    top: 40px;
    right: 0;
    background-color: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: 300px;
    max-height: 200px; /* Adjust this value as needed */
    overflow-y: auto; /* Enables vertical scrolling */
    z-index: 1000; /* Ensures dropdown is above other elements */
}
  
  .notification-item {
    padding: 10px;
    border-bottom: 1px solid #f0f0f0;
  }
  
  .notification-item.unseen {
    background-color: #e6f7ff;
  }
  
  .notification-separator {
    border: none;
    height: 1px;
    background-color: #f0f0f0;
    margin: 5px 0;
  }
  
  .notification-scroll {
    text-align: center;
    padding: 5px;
    font-style: italic;
  }
  
  .notification-item:last-child {
    border-bottom: none;
  }
  
  .admin-manage-section {
    display: flex;
    flex-direction: column;
  }
  
  .admin-manage-toggle {
    display: flex;
    align-items: center;
    cursor: pointer;
    color: var(--color-info-dark);
    margin-left: 2rem;
    gap: 1rem;
    position: relative;
    height: 3.7rem;
    transition: all 300ms ease;
  }
  
  .admin-manage-toggle:hover,
  .admin-manage-toggle.active {
    /* background: var(--color-light);
    color: var(--color-primary); */
    margin-left: 43px;
  }
  
  .admin-manage-submenu a:hover,
  .admin-manage-submenu .active-submenu {
    background-color: var(--color-light); /* Highlight active submenu item */
    color: var(--color-primary);
}
  .admin-manage-submenu {
    display: flex;
    flex-direction: column;
    margin-left: 20px; 
  }

  .admin-manage-submenu:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 2px;
    background-color: var(--color-light); /* Adjust color to fit theme */
    margin-left: -10px; /* Adjust this value to align with the visual flow */
}
  
  .admin-manage-submenu a {
    padding-left: 20px; /* Indent submenu items */
    font-size: 0.8rem; /* Smaller font size for submenu items */
    display: block;
  }
  
  .active-submenu {
    background: var(--color-light); /* Highlight active submenu item */
    color: var(--color-primary);
  }
  .logout-container {
    /* position: absolute; */
    bottom: 2rem;
    width: 100%;
}
.logout-container a {
    display: flex;
    align-items: center;
    color: var(--color-dark);
    text-decoration: none;
  }
  .logout-container a:hover {
    color: var(--color-primary);
  }
  

/* --------------------MEDIA QUERIES-------------------- */
/* @media screen and (max-width:1200px) {
    .container{
        width: 94%;
        grid-template-columns: 7rem auto 23rem;
    }
    
    aside .logo img {
        display: none;
    } 
    
    aside .sidebar h3{
        display: none;
    }
    
    aside .sidebar a{
        width: 5.6rem;
    }

    aside .sidebar a:last-child {
        position: relative;
        margin-top: 1.8rem;
    }
    aside {
        width: 5.6rem;
    }
    
} */

/* --------------------MEDIA QUERIES-MOBILE-------------------- */
@media screen and (max-width:768px) {
    .container {
        width:100%;
        grid-template-columns: 1fr;
    }
    
    aside .logo img {
        display: inline;
    } 
    
    aside .sidebar h3{
        display: inline;
    }
    aside .sidebar h2{
        display: inline;
    }
    aside .sidebar a{
        width: 100%;
        height: 3.4rem;
    }

    /* aside .sidebar a:last-child {
        position: absolute;
        margin-top: 5rem;
    } */
    aside {
        position: fixed;
        left: 0;
        background: var(--color-white);
        width: 18rem;
        z-index: 3;
        box-shadow: 1rem 3rem 4rem var(--color-light);
        height: 100vh;
        padding-right: var(--card-padding);
        display: none;
    }

    aside .close {
        display: inline-block;
        cursor: pointer;
    }
    aside {
        display: none;
    }

    aside.expanded {
        display: block;
        position: fixed;
        left: 0px;
        width: 243px;
        background: var(--color-white);
        height: 100vh;
        z-index: 1000;
        box-shadow: 1rem 3rem 4rem var(--color-light);
    }
    
}

@media screen and (max-width: 1200px) {
    
    aside {
        width: 60px;
    }

    aside.expanded {
        width: 243px;
    }

    aside .logo img {
        display: none;
    }

    aside.expanded .logo img {
        display: block;
    }

    aside .sidebar h3 {
        display: none;
    }
    .admin-manage-toggle {
        margin-left: 2rem;
      }

    aside.expanded .sidebar h3 {
        display: block;
    }
    aside .sidebar a {
        width: 5.6rem;
    }
    aside .close {
        display: inline-block;
        cursor: pointer;
    }

    aside.expanded .sidebar a {
        width: auto;
    }

    aside .sidebar a span {
        display: none;
    }

    aside.expanded .sidebar a span {
        display: inline;
    }
}

@media screen and (max-width: 480px) {
    aside.expanded {
        width: 100%;
        height: auto;
        position: relative;
    }

    .container {
        padding: 0;
    }

    .main-content {
        padding: 1rem;
    }


    .admin-manage-toggle {
        margin-left: 2rem;
      }
}