/* client/src/styles/ProjectAssignment.css */
.project-assignment {
    /* display: flex; */
    background-color: var(--color-background);
    padding: var(--card-padding);
    border-radius: var(--card-border-radius);
    box-shadow: var(--box-shadow);
    /* max-width: 600px; */
    /* margin: auto; */
    margin-bottom: 30px;
    /* overflow: hidden; */
}

.grid-container {
    display: grid;
    /* grid: auto / auto auto ; */
    /* grid-template-columns:  auto auto auto auto; Adjust column count as needed */
    grid-gap: 10px; /* Adjust gap between grid items */
    padding: 10px;
}

.item1 {
  grid-area: 1 / 1 / span 1 / span 2;
}
.item2 {
  grid-area: 1 / 3 / span 1 / span 2;
}
.item3 {
  grid-area: 2 / 1 / span 1 / span 2;
}
.item4 {
  grid-area: 2 / 3 / span 1 / span 2;
}

.grid-one-item {
    /* Your styles for individual grid items */
    grid-column: 1 / span 4;
    grid-gap: 10px; /* Adjust gap between grid items */
    padding: 10px;
}

.grid-two-item {
    grid-column-start: span 2;
}

.grid-four-item {
    display: grid;
    grid: auto / 24% 24% 24% 24%;
}

.grid-user-item {
    display: grid;
    grid: auto / 24% 24% 24% 24% 20%;
}

/* .card_elevation {
    border-radius: var(--radius-radius-lg, 16px);
    background: var(--color-surface-surface-default, #FFF);
    box-shadow: 0px 2px 10px 0px rgba(3, 83, 164, 0.10);
    padding: 24px 24px;
    gap: 15px;
    margin-bottom: 24px;
} */

.project-assignment h2 {
    color: var(--color-dark);
    margin-bottom: var(--padding-1);
    text-align: left;
    
}

.project-assignment label {
    /* display: grid; */
    color: var(--color-info-dark);
    margin-bottom: 0.5rem;
    
}

.project-assignment input,
.project-assignment textarea {
    width: 95%;
    padding: 0.5rem;
    border-radius: var(--border-radius-1);
    border: 1px solid var(--color-info-light);
    background-color: var(--color-white);
    margin-bottom: 0.5rem;
    
}

.project-assignment .width95 {
    width: 95%;
}

.project-assignment .remove-button{
    width: 7%;
    height: 57%;
    background-color: var(--color-primary);
    color: var(--color-white);
    border-radius: var(--border-radius-2);
    cursor: pointer;
    border: none;
    transition: background-color 0.3s;
    justify-content: center;
    align-items: center;
}

.project-assignment .remove-button:hover{
    background-color: var(--color-primary-variant);
}

.project-assignment .marginbottom {
    margin-bottom: 1rem;
}

.project-assignment .task-assignment {
    padding-top: 15px;
    padding: 0.5rem;
    margin-bottom: 0.5rem;
    
}

/* .project-assignment .task-assignment:first-child {
    border-top: none;
    padding-top: 0;
    
} */

.project-assignment button.grid-one-item {
    background-color: var(--color-primary);
    color: var(--color-white);
    padding: 0.5rem 1rem;
    border-radius: var(--border-radius-2);
    cursor: pointer;
    border: none;
    transition: background-color 0.3s;
    width: 100%;
    margin-bottom: 1rem;
    align-items: center;
    
}
.custom-highlighted {
    background-color: red !important;
    color: white !important;
  }
  
  /* Hover state for custom-highlighted dates */
.custom-highlighted:hover {
    background-color: darkred !important; /* Darker red on hover */
    color: white !important;
  }
  