.My-campaign-cards {
    width: 100%;
    max-width: 360px;
    margin: 0 auto;
    height: 300px;
    box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    position: relative;
}

.tag {
    position: absolute;
    top: -6px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 999;
    padding: .5rem 2rem;
    border-radius: 5px;
    color: #fff;
    box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.2);
}

.content {
    text-align: center;
    height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 1rem;
}

.footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;
}

.footer-l {
    display: flex;
    align-items: center;
    padding-left: 2rem;
    overflow: hidden;
    position: relative;
}

.footer-l img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-left: -20px;
    border: 2px solid white;
    z-index: 1;
}

.footer-l img:first-child {
    margin-left: 0;
}

.footer-l img:not(:first-child) {
    margin-left: -20px;
    z-index: 0;
}

.footer-r {
    padding-right: 2rem;
}

.My-campaign-page-header {
    width: 100%;
    margin-bottom: 1.4rem;
}

.campaign-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(360px, 1fr));
    gap: 6.6rem;
    width: 80vw;
    padding-top: 1.3rem;
    overflow-y: auto;
    scrollbar-width: none;
}
.campaign-container::-webkit-scrollbar {
    display: none;
}

.MyCampaign {
    background-color: var(--color-background);
    padding: var(--card-padding);
    border-radius: var(--card-border-radius);
    box-shadow: var(--box-shadow);
    width: 100%;
    margin: auto;
    overflow: hidden;
}

.profile-images-container {
    display: flex;
    align-items: center;
    padding-left: 2rem;
    overflow: hidden;
}

.profile-images-container img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 2px solid white;
    position: relative;
    margin-left: calc(-15px - 1.5 * var(--img-index));
    z-index: var(--img-index);
}

.profile-images-container img:first-child {
    margin-left: 0;
}

/* --------------------BIG-card------------------- */
.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    width: 90%;
    max-width: 1200px;
    max-height: 90vh;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    z-index: 1010 !important;
}

.MyCampaign.blur > :not(.modal-backdrop) {
    filter: blur(5px);
}

.modal-content {
    filter: none !important;
}

.modal-content {
    position: relative;
    margin: auto;
}

.modal-content h1 {
    font-size: 2rem;
    margin-bottom: 30px;
}

.modal-content h3 {
    font-size: 1.5rem;
    margin-bottom: 10px;
}

.modal-content p {
    font-size: 1rem;
    text-align: justify;
    max-width: 700px;
}

.profile-images-container {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    margin-bottom: 30px;
    justify-content: center;
}

.profile-images-container img {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    border: 3px solid white;
}

.modal-content .modal-header {
    display: flex;
    justify-content: space-between;
}

.modal-content button {
    padding: 15px 30px;
    font-size: 1.1rem;
    margin-top: 20px;
}

.add-button {
    margin-left: 10px;
    background: #a3d696;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    margin-left: 1rem;
}

.link-list {
    list-style-type: none;
    padding: 0;
    width: 80%;
}

.input-field {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    box-sizing: border-box;
}

.input-field:focus {
    border-color: #007bff;
    outline: none;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.link-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    border-bottom: 1px solid #ddd;
}

.link-name {
    flex: 1;
    text-decoration: none;
    color: #333;
}

.edit-project-btn {
    margin-left: 10px;
    background: #fedf00;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    margin-left: 1rem;
}

.user-list-btn {
    margin-left: 10px;
    background: #a3d696 !important;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    margin-left: 1rem;
}

.participant {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    border-bottom: 1px solid #ddd;
}

.participants {
    overflow-y: auto;
    max-height: 600px;
    width: 100%;
    margin-bottom: 20px;
}

.participant {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.participant-info {
    display: flex;
    align-items: center;
}

.participant-image {
    margin-right: 20px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
}

.status-indicator-container {
    display: flex;
    align-items: center;
}

.status-indicator {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 10px;
}

.status-indicator.done {
    background-color: green;
}

.green {
    background-color: #4CAF50;
}

.red {
    background-color: #F44336;
}

.orange {
    background-color: #FF9800;
}

.status-select {
    padding: 5px 10px;
    border-radius: 5px;
}

.status-indicator.not-done {
    background-color: red;
}

.status-container {
    display: flex;
    align-items: center;
}

.task-detail {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.task-detail img {
    margin-right: 15px;
}

.task-detail select {
    margin-left: auto;
}

.View-file button {
    padding: 8px 15px;
    background-color: var(--color-primary);
    color: var(--color-white);
    border: none;
    border-radius: var(--border-radius-1);
    cursor: pointer;
    font-size: 1rem;
    margin-top: 0rem;
    font-family: 'Arial', sans-serif;
    transition: background-color 0.3s ease, transform 0.1s ease;
    text-align: center;
    display: inline-block;
    margin-right: 1rem;
    text-decoration: none;
}

.View-file button:hover {
    background-color: var(--color-primary-variant);
    transform: translateY(-2px);
}

.View-file button:active {
    transform: translateY(1px);
}

.View-file button:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(115, 128, 236, 0.5); 
}

.Attach-File label {
    padding: 8px 15px;
    background-color: var(--color-primary);
    color: var(--color-white);
    border: none;
    border-radius: var(--border-radius-1);
    cursor: pointer;
    font-size: 1rem;
    font-family: 'Arial', sans-serif;
    margin-top: 0rem;
    transition: background-color 0.3s ease, transform 0.1s ease;
    text-align: center;
    display: inline-block;
    margin-left: 1rem;
    text-decoration: none;
    width: 100px;
    height: 32px;
}

.Attach-File label:hover {
    background-color: var(--color-primary-variant);
    transform: translateY(-2px);
}

.Attach-File label:active {
    transform: translateY(1px);
}

.Attach-File label:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(115, 128, 236, 0.5);
}

.pagination-controls {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
}

.pagination-controls button {
    cursor: pointer;
    background-color: var(--color-primary);
    color: var(--color-white);
    border: none;
    padding: 0.8rem 1.6rem;
    margin: 0 0.5rem;
    border-radius: var(--border-radius-2);
    transition: background-color 0.3s ease;

    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

.pagination-controls button:disabled {
    background-color: var(--color-info-light);
    cursor: not-allowed;
}

.pagination-controls button:hover:not(:disabled) {
    background-color: var(--color-primary-variant);
}

.pagination-controls span {
    margin: 0 1rem;
    color: var(--color-dark);
}

.beautiful-button {
    padding: 10px 20px;
    background-color: var(--color-primary);
    color: white;
    border: none;
    border-radius: var(--border-radius-2);
    cursor: pointer;
    margin-top: 10px;
}

.excel-url-modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.excel-url-modal-content {
    background-color: white;
    padding: 20px;
    border-radius: var(--border-radius-2);
}

.excel-url-input {
    width: 100%;
    margin-bottom: 20px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: var(--border-radius-1);
}

.delete-project-btn {
    background-color: #ff4d4d !important;
    color: white;
    border: none;
    cursor: pointer;
    padding: 5px 10px;
    margin-right: 20px;
    border-radius: 5px;
    font-size: 1em;
    margin-left: 10px;
    transition: background-color 0.3s ease;
}

.delete-project-btn:hover {
    background-color: #ff0000;
}

.delete-confirmation-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    text-align: center;
    width: 300px;
}

.delete-confirmation-modal button {
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    margin: 10px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.confirm-delete-btn {
    position: relative;
    background-color: #ff4d4d;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    margin: 10px;
    cursor: pointer;
    overflow: hidden;
}

.confirm-delete-btn:hover {
    background-color: #ff0000;
}

.cancel-delete-btn {
    background-color: #cccccc;
    color: white;
}

.cancel-delete-btn:hover {
    background-color: #b3b3b3;
}

.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
}

.confirm-delete-fill {
    position: absolute;
    top: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color: rgba(255, 0, 0, 0.5);
    transition: width 2s linear;
    border-radius: 5px;
}

.user-list-modal {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 80%;
    max-height: 80%;
    overflow-y: auto;
    z-index: 1051;
}

.user-list-modal h2 {
    text-align: center;
    margin-bottom: 20px;
}

.user-card {
    display: flex;
    align-items: center;
    padding: 20px;
    border-radius: 5px;
    margin-bottom: 15px;
    cursor: pointer;
    transition: background-color 0.2s;
    border-bottom: 1px solid #eaeaea;
}

.user-card:hover {
    background-color: #f0f0f0;
}

.user-avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 15px;
    object-fit: cover;
}

.user-name {
    font-size: 16px;
    font-weight: 500;
}

.large-icon {
    font-size: 40px;
    color: #FF9800;
    margin-right: 15px;
}

.file-item {
    display: flex;
    align-items: center;
    padding: 20px;
    border-radius: 5px;
    margin-bottom: 15px;
    cursor: pointer;
    transition: background-color 0.2s;
    border-bottom: 1px solid #eaeaea;
}

.file-item:hover {
    background-color: #f0f0f0;
}

.files-header {
    display: flex;
    align-items: center;
    position: relative;
}

.back-icon {
    cursor: pointer;
    margin-right: 10px;
    font-size: 24px;
    color: #333;
}

.user-list-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.attach-file-btn {
    display: flex;
    align-items: center;
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.2s;
}

.attach-file-btn:hover {
    background-color: #0056b3;
}

.attach-file-btn svg {
    margin-right: 8px;
}

.file-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.file-name {
    flex: 1;
    cursor: pointer;
}

.delete-file-icon {
    color: red;
    cursor: pointer;
    margin-left: 10px;
    font-size: 24px;
    transition: transform 0.2s ease-in-out;
}

.delete-file-icon:hover {
    animation: shake 0.5s;
    animation-iteration-count: infinite;
}

@keyframes shake {
    0% { transform: translate(0.5px, 0.5px) rotate(0deg); }
    10% { transform: translate(-0.5px, -1px) rotate(-0.5deg); }
    20% { transform: translate(-1.5px, 0px) rotate(0.5deg); }
    30% { transform: translate(1.5px, 1px) rotate(0deg); }
    40% { transform: translate(0.5px, -0.5px) rotate(0.5deg); }
    50% { transform: translate(-0.5px, 1px) rotate(-0.5deg); }
    60% { transform: translate(-1.5px, 0.5px) rotate(0deg); }
    70% { transform: translate(1.5px, 0.5px) rotate(-0.5deg); }
    80% { transform: translate(-0.5px, -0.5px) rotate(0.5deg); }
    90% { transform: translate(0.5px, 1px) rotate(0deg); }
    100% { transform: translate(0.5px, -1px) rotate(-0.5deg); }
}

.circular-progress-container {
    position: absolute;
    bottom: 20px;
    top: 10px;
    left: 10%;
    transform: translateX(-50%);
    width: 50px;
    height: 50px;
}

.circular-progress-bar .CircularProgressbar-path {
    stroke: var(--color-primary);
}

.circular-progress-bar .CircularProgressbar-trail {
    stroke: var(--color-info-light);
}

.circular-progress-bar .CircularProgressbar-text {
    fill: var(--color-dark);
}

.circular-progress-bar .CircularProgressbar-background {
    fill: var(--color-background);
}

.tag.status-done {
    color: #fff;
    background: var(--color-success);
}

.tag.status-not-done {
    color: #fff;
    background: var(--color-warning);
}

.tag.status-problem {
    color: #fff;
    background: var(--color-danger);
}

.tag.status-wip {
    color: #fff;
    background: var(--color-primary);
}

@media screen and (max-width: 768px) {
    .campaign-container {
        grid-template-columns: 1fr;
    }

    .modal-content {
        width: 95%;
        max-width: 95%;
        max-height: 95%;
    }
}

@media screen and (max-width: 480px) {
    .campaign-container {
        grid-template-columns: 1fr;
        padding: 1rem;
    }

    .modal-content {
        width: 95%;
        max-width: 95%;
        max-height: 95%;
        padding: 1rem;
    }

    .My-campaign-cards {
        height: auto;
    }

    .tag {
        padding: 0.5rem 1rem;
    }

    .content {
        padding: 1rem;
    }

    .footer {
        flex-direction: column;
        align-items: flex-start;
    }

    .footer-l, .footer-r {
        padding: 0.5rem;
    }

    .profile-images-container {
        padding-left: 0;
    }

    .profile-images-container img {
        margin-left: 0.5rem;
    }
}


