.user-timesheet-page {
    padding: 20px;
    background-color: #f4f5f7;
    min-height: 100vh;
    width: 100%;
  }
  
  .user-timesheet-page h2 {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .date-filter {
    margin-bottom: 20px;
    display: flex;
    justify-content: flex-start;
    gap: 10px;
  }
  
  .ant-picker {
    width: 250px;
  }
  
  .ant-table {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }
  
  .ant-table-thead > tr > th {
    background-color: #fafafa;
    font-weight: bold;
  }
  
  .ant-table-row {
    transition: background-color 0.3s;
  }
  
  .ant-table-row:hover {
    background-color: #f0f0f0;
  }
  