.request-review-container {
    padding: 20px;
    width: 100%;
    margin: 0 auto;
    background-color: var(--color-background);
    border-radius: var(--card-border-radius);
    box-shadow: var(--box-shadow);
}

.header-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 20px;
}

.header-container h1 {
    color: var(--color-dark);
    margin-bottom: 10px;
}

.filters-container {
    display: flex;
    gap: 10px;
    width: 100%;
    margin-bottom: 20px;
}

.search-input, .filter-dropdown {
    flex: 1;
    padding: 10px;
    border: 1px solid var(--color-info-dark);
    border-radius: var(--border-radius-1);
    font-size: 16px;
    color: var(--color-dark-variant);
    background-color: var(--color-white);
    transition: border-color 0.3s;
}

.search-input:focus, .filter-dropdown:focus {
    border-color: var(--color-primary);
    outline: none;
}

.request-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    color: var(--color-dark);
}

.request-table th, .request-table td {
    border: 1px solid var(--color-info-light);
    padding: 8px;
    text-align: left;
}

.request-table th {
    background-color: var(--color-primary);
    color: var(--color-white);
}

.request-table tr:nth-child(even) {
    background-color: var(--color-light);
}

.request-table tr:hover {
    background-color: var(--color-primary-light);
}

.download-button {
    padding: 5px 10px;
    background-color: var(--color-primary);
    color: var(--color-white);
    border: none;
    border-radius: var(--border-radius-1);
    cursor: pointer;
}

.download-button:hover {
    background-color: var(--color-primary-dark);
}

.status-container {
    display: flex;
    align-items: center;
}

.status-dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 5px;
}

.reason-cell {
    position: relative;
    cursor: pointer;
}

.reason-popup {
    display: none;
    position: absolute;
    bottom: 100%;
    left: 0;
    background-color: white;
    border: 1px solid var(--color-info-dark);
    padding: 10px;
    box-shadow: var(--box-shadow);
    z-index: 10;
    max-height: 100px; /* Set a max height for the popup */
    overflow-y: auto; /* Enable vertical scrolling */
    border-radius: var(--border-radius-1); /* Rounded edges */
    white-space: pre-wrap;
}

.reason-popup::-webkit-scrollbar {
    width: 0;  /* Remove scrollbar space */
    background: transparent;  /* Optional: just make scrollbar invisible */
}

.reason-cell:hover .reason-popup {
    display: block;
    width: 200px;
}

@media screen and (max-width: 1200px) {
    .optional-column {
        display: none;
    }

    .filters-container {
        flex-direction: column;
        gap: 5px;
    }

    .search-input, .filter-dropdown {
        width: 100%;
    }

    .request-table th, .request-table td {
        padding: 6px;
    }

    .header-container {
        align-items: center;
    }
}
@media screen and (max-width: 412px) {
    .dashboard-layout {
        flex-direction: column;
        width: 90%;
    }
    .navbar-container {
        width: 80%;
    }
    .request-table{
        width: 10%;
    }
}
